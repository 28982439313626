import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useJsApiLoader } from "@react-google-maps/api";
import Routes from "./routes";
import Spinner from "./components/spinners/eevom";

const LIBRARIES = ["maps", "places"];
function App() {
  const { isLoading, notification } = useSelector((state) => state.Auth);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoaded } = useJsApiLoader({
    id: "__googleMapsScriptId",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "my",
    libraries: LIBRARIES,
  });

  useEffect(() => {
    if (
      notification?.type !== undefined &&
      notification?.message !== undefined
    ) {
      if (window.location.pathname === "/login") {
        if (
          notification.message !==
            "You are not authorized to perform this operation" &&
          notification.message !== "logout"
        )
          enqueueSnackbar(notification.message, { variant: notification.type });
      } else {
        enqueueSnackbar(notification.message, { variant: notification.type });
      }
    }
  }, [notification, enqueueSnackbar]);

  if (isLoaded === true) return <Routes>{isLoading > 0 && <Spinner />}</Routes>;
  else return <Spinner />;
}

export default App;
